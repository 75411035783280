import { userAxios } from '@/services'

const tutorialList = async (perPage) => {
    try {
        return await userAxios.get(`tutorial/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const tutorialFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`tutorial/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    tutorialList,
    tutorialFilter
}