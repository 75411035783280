<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{
          backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`,
        }"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary">360 University</h2>
          <b-card-text class="mb-2">
            or choose a category to quickly find the help you need
          </b-card-text>

          <!-- form -->
          <b-form class="faq-search-input">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="faqSearchQuery"
                placeholder="Search faq..."
              />
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >
        <!-- payment tab -->
        <b-tab
          v-for="(categoryObj, categoryName, index) in tutorials"
          :key="categoryName"
          :active="!index"
        >
          <!-- title -->
          <template #title>
            <!-- <feather-icon
              :icon="categoryObj.icon"
              size="18"
              class="mr-1"
            /> -->
            <span class="font-weight-bold">{{ categoryObj.name }}</span>
          </template>
          <faq-question-answer
            :text="{
              title: categoryObj.name,
              subtitle: categoryObj.description,
            }"
            :options="categoryObj.items"
          />
          <!-- <div class="mt-2">
            <div
              v-for="itemTutorial in categoryObj.items.data"
              :key="itemTutorial.id"
            >
              <faq-question-answer :options="itemTutorial" />
            </div>
          </div> -->
        </b-tab>
        <!--/ payment tab -->

        <!-- sitting lady image -->
        <template #tabs-end>
          <b-img
            fluid
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-auto"
          />
        </template>
        <!--/ sitting lady image -->
      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->

    <!-- contact us -->
    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>¿Todavía tiene alguna pregunta?</h2>
          <b-card-text class="mb-3">
            Si no encuentra una pregunta en nuestras FAQ, siempre puede ponerse en contacto con nosotros.
            Le responderemos en breve.
          </b-card-text>
        </b-col>
        <b-col sm="6">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="PhoneCallIcon" size="18" />
            </b-avatar>
            <h4>+57 312 6732928</h4>
            <span class="text-body">La mejor manera de obtener una respuesta más rápida</span>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="MailIcon" size="18" />
            </b-avatar>
            <h4>develop.department@innovamg.us</h4>
            <span class="text-body">Estaremos encantados de ayudarle.</span>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <!--/ contact us -->
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
} from "bootstrap-vue";
import FaqQuestionAnswer from "./FaqQuestionAnswer.vue";
import axiosT from "@/services/admin/tutorial/tutorialFaq";

export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    FaqQuestionAnswer,
  },
  data() {
    return {
      faqSearchQuery: "",
      faqData: {},
      tutorials: [],
      setTimeoutBuscador: null,
    };
  },
  watch: {
    faqSearchQuery(){
      this.fetchData()
    }
  },
  mounted() {
    this.getTutorials();
  },
  methods: {
    getTutorials() {
      axiosT.tutorialList(10).then(({ registro: { data } }) => {
        const formatData = data.map((item) => {
          const formatItem = {
            name: item.name,
            description: item.description,
            items: item.items.data,
          };
          return formatItem;
        });
        this.tutorials = formatData;
      });
    },
    fetchData() {
      const dataFilter = {
        name: this.faqSearchQuery,
      };
      clearTimeout(this.setTimeoutBuscador);
      this.setTimeoutBuscador = setTimeout(() => {
        axiosT.tutorialFilter(10, dataFilter).then(({ data }) => {
          this.tutorials = data;
        });
      }, 400);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";
</style>
