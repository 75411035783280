<template>
  <div v-if="options">
    <div class="d-flex align-items-center">
      <b-avatar rounded size="42" variant="light-primary" class="mr-1">
        <feather-icon icon="SearchIcon" size="20" />
      </b-avatar>
      <div>
        <h4 class="mb-0">
          {{ text.title }}
        </h4>
        <span>{{ text.subtitle }}</span>
      </div>
    </div>

    <app-collapse id="faq-payment-qna" accordion type="margin" class="mt-2">
      <app-collapse-item
        v-for="(data, index) in options"
        :key="index"
        :title="data.title"
      >
        <div class="output ql-snow">
          <div ref="content" class="ql-editor" v-html="data.conf"></div>
        </div>
      </app-collapse-item>
    </app-collapse>

    <!--/ collapse -->
  </div>
</template>

<script>
import { BAvatar } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

export default {
  components: {
    BAvatar,
    AppCollapseItem,
    AppCollapse,
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    text: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
